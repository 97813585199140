import React from "react";
import Card from "react-bootstrap/Card";
import Anchor from "./Anchor";

import "../../styles/components/elements/blog_card.scss";

const BlogCard = ({ info }) => {
  return (
    <Card className="blogpost-card" as={Anchor} href={info.url}>
      <Card.Img
        variant="top"
        className="blogpost-img"
        src={info.data.image_mobile.url}
      />
      <Card.Body className="blogpost-body">
        <Card.Text className="post-tag">{info.data.tag}</Card.Text>
        <div className="outer-box d-flex flex-column justify-content-between">
          <Card.Title className="post-title">{info.data.title.text}</Card.Title>
          <Card.Text className="post-date mt-auto">
            {info.data.publication_date}
          </Card.Text>
        </div>
      </Card.Body>
    </Card>
  );
};

export default BlogCard;
